import React, {Component } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, MenuItem, Popover, Select, StandardTextFieldProps, Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Talk from "talkjs";
import { firebase_app } from '../FirebaseInit';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';

import App from '../App';
import { DropzoneArea } from 'mui-file-dropzone';
import seppOk from '../assets/img/sepp-ok.png'
import seppOkSmall from '../assets/img/sepp-ok-small.png'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Guide from './Guide';


 
const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    height: "60%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: "lightgrey",
    overflow: "auto",
    opacity: 0.95
  };

const modalStyleMini = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "60%",
    maxWidth: "300px",
    maxHeight: "200px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: "lightgrey",
    overflow: "auto",
    opacity: 0.95
  };


  const {
    REACT_APP_REDIRECT_LOGIN_URL
} = process.env;

const isEmptyString = (data: string): boolean => typeof data === "string" && data.trim().length == 0;

export const EmailRegex=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

interface CreateIssuePanelProps {
  style?: React.CSSProperties;
  balance?: string;
  mainApp: App;
}

class CreateIssuePanel extends Component<CreateIssuePanelProps> {

    state = {
        showLoading_Login: false,
        creationCompleteModal: false,

        email: "", 
        firstname: "",
        lastname: "",
        mobile: "",
        policynumber: "",
        insuranceagency: "",
        files: [],
        filesJson: "",
        gender: "",
        issueDate: moment().format("YYYY-MM-DD"),
        birthDate: "",
        issueText: "",
        emailVerified: false,
        emailVerificationPopover: false,
        loginEmailSent: false,
        currentUser: null,
        mandatoryFieldsMissingModalOpen: false,
        mandatoryFieldsMissingTxt: "",
        dataPrivacyCheckbox: false,
        termsCheckbox: false,
        proxyAuthoritityCheckbox: false,
        craftsmanServiceCheckbox: true,
        whyProxyAuthModalOpen: false,
        workermanServiceDetailsModalOpen: false,
        emailVerificationInvalid: false,
        termsDetailsModalOpen: false,
        privacyTermsModalOpen: false,
        issueLocation: ""
    }
    baseState: any;
    _stateInterval: any;
    mainApp?: App;
    _firebaseListenerInitialized: boolean = false;

    userdataRef: firebase.default.firestore.CollectionReference<firebase.default.firestore.DocumentData>;
    emailVerifyButton: React.RefObject<HTMLButtonElement>;

    constructor(props: CreateIssuePanelProps) {
        super(props);

        this.baseState = this.state;
        this.mainApp = props.mainApp;    
        this.emailVerifyButton = React.createRef();

        this.userdataRef = firebase_app
        .firestore()
        .collection('userdata');
    }
 
    componentDidMount = () => { 

        this._processProps();
        this._checkEmailLinkLogin();
        this._startStatePolling();
    }

    componentWillUnmount = async () => { 
        if(this.mainApp != null){


        }

    }

    _checkEmailLinkLogin = async () => {
        // reinit auth if present
        firebase_app.auth();
        let currentUser = await firebase_app.auth().currentUser;

        //var email = window.localStorage.getItem('emailForSignIn');
        const urlParams = new URLSearchParams(window.location.search);
        var email = urlParams.get('mail')?.toString();

        this.setState({email: email});

        if (firebase_app.auth().isSignInWithEmailLink(window.location.href) && !currentUser) {
            if(email){
                firebase_app
                    .auth()
                    .signInWithEmailLink(email, window.location.href)
                    .then(function(result) {
                        //window.localStorage.removeItem('emailForSignIn');
                    })
                    .catch(function(error) {
                    alert(error);
                    });
            }
        }
    }

    _startStatePolling = () => {
        this.checkState();
        if(this._stateInterval){
            clearInterval(this._stateInterval);
        }
        this._stateInterval = setInterval(this.checkState, window.dataRefreshInterval );
    }

    componentDidUpdate(prevProps: CreateIssuePanelProps) {
        this._processProps();        
    }

    _processProps = () => {
        this.mainApp = this.props.mainApp;
    }

    checkState = async () => {  
        this._startFirebaseListener();
    }  

    _startFirebaseListener = async () => {
        if(this._firebaseListenerInitialized == true){
            let currentUser = await firebase_app.auth().currentUser;
            if(!currentUser){
                this._firebaseListenerInitialized = false;
                return;
            }
                      
            return;
        }

        let currentUser = await firebase_app.auth().currentUser;
        if(this.mainApp && currentUser){
            this._firebaseListenerInitialized = true;
            this.setState({currentUser: currentUser, email: currentUser.email, loginEmailSent: false, emailVerified: true});

            //this.mainApp?.setModalClose();   // dont control the login modal here
        }else{
            //this.mainApp?.setModalOpen();    // dont control the login modal here           
        }

    }

    isEmail = (val: string) => {    
        if(!EmailRegex.test(val)){
          return false;
        }
        return true;
    }

    checkMandatoryInputs = () => {

        // E-Mail, Firstname, Lastname, Birthday, issueDate, issueText


        return false;
    }

    onClick_Create = async () => {

        let currentUser = await firebase_app.auth().currentUser;

        let userData = { // user input
            email: this.state.email,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            mobile: this.state.mobile,
            policynumber: this.state.policynumber,
            insuranceagency: this.state.insuranceagency,
            gender: this.state.gender,
            birthDate: this.state.birthDate,
            issueDate: this.state.issueDate,
            issueText: this.state.issueText,
            timestamp: new Date(),
            userId: currentUser?.uid,
            craftsmanService: this.state.craftsmanServiceCheckbox,
            issueLocation: this.state.issueLocation
        };

        let missingFieldsTxt = "";
        if(isEmptyString(userData.email)){
            missingFieldsTxt = missingFieldsTxt + ", E-Mail";
        }
        if(isEmptyString(userData.firstname)){
            missingFieldsTxt = missingFieldsTxt + ", Vorname";
        }
        if(isEmptyString(userData.lastname)){
            missingFieldsTxt = missingFieldsTxt + ", Nachname";
        }
        if(isEmptyString(userData.birthDate)){
            missingFieldsTxt = missingFieldsTxt + ", Geburtstag";
        }
        if(isEmptyString(userData.issueText)){
            missingFieldsTxt = missingFieldsTxt + ", Beschreibung";
        }

/*
        dataPrivacyCheckbox: false,
        termsCheckbox: false,
        proxyAuthoritityCheckbox: false,
        craftsmanServiceCheckbox: true
*/
        if(!this.state.dataPrivacyCheckbox){
            missingFieldsTxt = missingFieldsTxt + ", Datenschutzbestimmungen";
        }
        if(!this.state.termsCheckbox){
            missingFieldsTxt = missingFieldsTxt + ", SEPP-AGB";
        }
        if(!this.state.proxyAuthoritityCheckbox){
            missingFieldsTxt = missingFieldsTxt + ", Vollmacht";
        }


        missingFieldsTxt = missingFieldsTxt.replace(", ", "");

        this.setState({
            mandatoryFieldsMissingTxt: missingFieldsTxt,
            mandatoryFieldsMissingModalOpen: !isEmptyString(missingFieldsTxt)
        });
        

        if(!isEmptyString(missingFieldsTxt)){
            return; // dont write to db
        }

        userData.birthDate = moment(new Date(userData.birthDate)).format("YYYY-MM-DD");

        //console.log("files", this.state.files);
        //let userDataJson = JSON.stringify(userData);
        //localStorage.setItem("unprocessedIssueJSON", userDataJson);
        

        const documentReference = this.userdataRef.doc(currentUser?.uid);   

        /*
            state = {
                showLoading_Login: false,

                email: "", 
                firstname: "",
                lastname: "",
                mobile: "",
                policynumber: "",
                insuranceagency: "",
                files: [],
                gender: "",
                issueDate: moment().format("YYYY-MM-DD"),
                birthDate: null 
            }
        */
    
        this.setState({showLoading_Login: true});

        let result = documentReference
        .set({
            email: currentUser?.email,

        }).then(result => {
            console.log("result", result);
        }).catch(error => {
            console.log("error", error);
        });   
    
        let newIssueResult = documentReference.collection('issues').add(userData).then(result => {
        console.log("result", result);
        // create was successful. now upload documents
        this.setState({showLoading_Login: false});
        this._uploadFiles(result.id);

        }).catch(error => {
            console.log("error", error);
            this.setState({showLoading_Login: false});
        });

      }
    
      handleFilesChange = (files: any) => {
        console.log("handleFilesChange", files);

        this.setState({"files": files});
      }
    
      handleFileUploadError = () => {
    
      }


      _uploadFiles = async (issueId: string) => {
        if(this.state.files.length == 0){
            this.setState({creationCompleteModal: true}); // keine files zum uploaden --> hier ist es fertig gespeichert
            return;
        }
        let userId = await firebase_app.auth().currentUser?.uid;

        var storage = firebase_app.storage();

        this.setState({showLoading_Login: true});

        let uploadCounter = 0;

        for( let i = 0; i < this.state.files.length; i++){

            let file = this.state.files[i] as File;

            let storageReference = storage.ref(`images/${userId}/${issueId}/${file.name}`);
            let uploadTask = storageReference.put(file);

            uploadTask.on("state_changed", {
                error: error => {
                  console.error(error);
                  this.setState({showLoading_Login: false});
                },
                complete: () => {
                    uploadCounter++;

                    if(uploadCounter == this.state.files.length){
                        this.setState({showLoading_Login: false});

                        this.setState({creationCompleteModal: true}); // fertig gespeichert nach upload
                    }
                }
              });
        }

        
    }

    setModalClose = () => {
        this.setState({creationCompleteModal: false});
    }

    sendEmailLogin = async () => {
        /*
        actionCodeSettings.url = actionCodeSettings.url + "?mail=" + window.encodeURIComponent(this.state.email);

        this.setState({showLoading_Login: true, loginEmailSent: true});
        await firebase_app.auth().sendSignInLinkToEmail(this.state.email, actionCodeSettings).catch((error) => {
            window.alert(error);
        });
        this.setState({showLoading_Login: false});
        */

        this.setState({showLoading_Login: true, loginEmailSent: true});
        var sendSeppVerificationEmail = firebase_app.functions().httpsCallable('sendSeppVerificationEmail');   
        sendSeppVerificationEmail({ mail: this.state.email, 'redirectUrl': REACT_APP_REDIRECT_LOGIN_URL  })
        .then((result: any) => {
            this.setState({showLoading_Login: false});
            console.log("sendSeppVerificationEmail", result);
        }).catch(error => {
            this.setState({showLoading_Login: false});
            alert("Da ist etwas schief gelaufen: " + error);
        });
    }

    onClose_EmailVerificationPopover = () => {

    }
    onClick_EmailVerificationButton = () => {
        let emailOk = this.isEmail(this.state.email);
        if(emailOk){
            this.setState({emailVerificationPopover: true});
        }
    }

    checkEmailVerification = async () => {
        let mail = this.state.email;
        let currentUser = await firebase_app.auth().currentUser;
        if(currentUser){
            if(mail != currentUser.email){
                this.setState({emailVerified: false, emailVerificationInvalid: true});
            }
        }
    }


    render = () => {
        return (
            <div>
                <h1 style={{color: 'white'}}><span style={{fontWeight: "bold", color: "white"}}>Versicherungsmeldung</span> erstellen</h1>
            {/*<Paper className={"minHeight"} style={{padding: "16px", paddingTop: "16px"}}>*/}
            <Grid container spacing={6} style={{marginTop: "96px"}}>
                <Grid item xs={12} md={4}>
                    {/* <Paper className={"minHeight"} style={{padding: "16px", paddingTop: "16px"}}> */}
{/*                         <Typography sx={{ fontSize: 16, marginBottom: 3 }} color="secondary" gutterBottom>
                            Deine Kontaktdaten
                        </Typography>     */}        
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img className={"hideLarge"} style={{ maxWidth: "60%" }} src={seppOkSmall} />
                            </div>
                            <TextField required={true} error={!this.isEmail(this.state.email)} color={this.isEmail(this.state.email)?"primary":"error"} sx={{ fontSize: 14, marginBottom: 2 }} onChange={(event) => {this.setState({email: event.target.value}); this.checkEmailVerification();}} value={this.state.email} label={"E-Mail"} type="text" fullWidth={true}></TextField>
                            <Typography variant='body2' color="text.secondary" sx={{textAlign: "center", fontSize: "10pt", marginBottom: 1, display: this.state.emailVerified == false?"":"none"}}>
                                <b>Bitte gib die E-Mail Adresse ein mit der du kommunizieren möchtest.</b><br/>
                                Nach einer kurzen Bestätigung deiner E-Mail Adresse kann es sofort losgehen.
                            </Typography>
                            <Typography variant='body2' color="text.secondary" sx={{fontSize: "10pt", marginBottom: 2, textAlign: "center", display: this.state.emailVerified == true?"":"none"}}>
                                ✅ E-Mail erfolgreich verifiziert
                            </Typography>
                            <Button onClick={this.onClick_EmailVerificationButton} ref={this.emailVerifyButton} sx={{marginBottom: 2, marginTop: 1, display: ((this.state.email != "" && this.state.currentUser == null) || this.state.emailVerificationInvalid)?"":"none"}} size="small" variant="outlined" fullWidth={true}>Weiter<ArrowDownwardIcon></ArrowDownwardIcon>  </Button>
                            <Popover
                                anchorEl={this.emailVerifyButton.current}
                                open={this.state.emailVerificationPopover}
                                onClose={this.onClose_EmailVerificationPopover}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <Typography sx={{ p: 2 }}>
                                    Wir müssen deine E-Mail verifizieren.
                                </Typography>
                                <Button onClick={this.sendEmailLogin} sx={{marginBottom: 2, marginTop: 1}} size="small" variant="text" fullWidth={true}>E-Mail senden <ForwardToInboxIcon style={{marginLeft: "8px"}}></ForwardToInboxIcon></Button>
                                <Typography sx={{ p: 2, display: this.state.loginEmailSent?"":"none" }}>
                                ✅ E-Mail gesendet.<br/>Bitte klicke auf den darin enthaltenen Link um deine Adresse zu bestätigen.<br/><br/>
                                <b>Falls du keine E-Mail erhalten hast, prüfe bitte deinen <span style={{textDecoration: 'underline'}}>Spam-Ordner</span>.</b>
                                </Typography>
                                <Button onClick={(event) => {this.setState({emailVerificationPopover: false})}} sx={{marginBottom: 2, marginTop: 1}} size="small" variant="text" fullWidth={true}>Schließen</Button>

                            </Popover>
                            
                            <div style={{display: this.state.loginEmailSent?"":"none"}}>
                                <LinearProgress />
                                <Typography color={"primary"} sx={{ p: 2 }}>
                                    Sobald deine E-Mail bestätigt wurde kann es auch schon losgehen!
                                </Typography>
                            </div>
                        </div>
                        <div>
                            {/* <Typography variant='body2' color="text.secondary" sx={{fontSize: "10pt", marginBottom: 1}}>
                                Wer bist du?
                            </Typography> */}
                            <TextField required={true} disabled={this.state.emailVerified == false} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} onChange={(event) => {this.setState({firstname: event.target.value})}} value={this.state.firstname} label={"Vorname"} type="text" fullWidth={true}></TextField>
                            <TextField required={true} disabled={this.state.emailVerified == false} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} onChange={(event) => {this.setState({lastname: event.target.value})}} value={this.state.lastname} label={"Nachname"} type="text" fullWidth={true}></TextField>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                {/* <TextField required={true} disabled={this.state.emailVerified == false} sx={{ fontSize: 14, marginBottom: 2 }} InputLabelProps={{ shrink: true }} onChange={(event) => {this.setState({birthDate: event.target.value})}} value={this.state.birthDate}  label={"Geburtsdatum"} type="date" fullWidth={true}></TextField> */}
                                <DatePicker
                                    disableFuture
                                    inputFormat="dd.MM.yyyy"
                                    label={"Geburtsdatum"}
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    disabled={this.state.emailVerified == false}
                                    onChange={(value) => {this.setState({birthDate: value})}} 
                                    value={this.state.birthDate}
                                    onError={(errMsg) => {console.log(errMsg);}}
                                    renderInput={(params) => <TextField 
                                        required={true} 
                                        fullWidth={true} 
                                        {...params}
                                        error={false}
                                         />}
                                />
                            </LocalizationProvider>

                            <FormControl disabled={this.state.emailVerified == false} fullWidth sx={{ my:4, fontSize: 14, marginBottom: 2 }}>
                                <InputLabel id="gender-selectbox">Geschlecht</InputLabel>
                                <Select
                                
                                labelId="gender-selectbox"
                                value={this.state.gender}
                                label="Geschlecht"
                                onChange={(event) => this.setState({ gender: event.target.value })}
                                >
                                <MenuItem value={"m"}>männlich</MenuItem>
                                <MenuItem value={"w"}>weiblich</MenuItem>
                                <MenuItem value={"d"}>div</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField disabled={this.state.emailVerified == false} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} label={"Mobiltelefon"} onChange={(event) => {this.setState({mobile: event.target.value})}} value={this.state.mobile} type="text" fullWidth={true}></TextField>

                            <TextField disabled={this.state.emailVerified == false} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} label={"Polizzennummer (optional)"} onChange={(event) => {this.setState({policynumber: event.target.value})}} value={this.state.policynumber} type="text" fullWidth={true}></TextField>
                            {/* <TextField sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} label={"Versicherungsgesellschaft"}  onChange={(event) => {this.setState({insuranceagency: event.target.value})}} value={this.state.insuranceagency}   type="text" fullWidth={true}></TextField> */}

                            <FormControl disabled={this.state.emailVerified == false} fullWidth sx={{ my:0, fontSize: 14, marginBottom: 2 }}>
                                <InputLabel id="insuranceagency-selectbox">Versicherungsgesellschaft</InputLabel>
                                <Select
                                value={this.state.insuranceagency}
                                label="Versicherungsgesellschaft"
                                onChange={(event) => this.setState({ insuranceagency: event.target.value })}
                                >
                                <MenuItem value={"Allianz"}>Allianz</MenuItem>
                                <MenuItem value={"Generali"}>Generali</MenuItem>
                                <MenuItem value={"Uniqa"}>Uniqa</MenuItem>
                                <MenuItem value={"Wiener Städtische"}>Wiener Städtische</MenuItem>
                                <MenuItem value={"Donau"}>Donau</MenuItem>
                                <MenuItem value={"Helvetia"}>Helvetia</MenuItem>
                                <MenuItem value={"Tiroler"}>Tiroler</MenuItem>
                                <MenuItem value={"VAV"}>VAV</MenuItem>
                                <MenuItem value={"Ergo"}>Ergo</MenuItem>
                                <MenuItem value={"Zürich"}>Zürich</MenuItem>
                                <MenuItem value={"Merkur"}>Merkur</MenuItem>
                                <MenuItem value={"ÖBV"}>ÖBV</MenuItem>
                                <MenuItem value={"ka"}>weiß ich leider nicht</MenuItem>   
                                </Select>

                                <TextField disabled={this.state.emailVerified == false} sx={{ fontSize: 14, marginBottom: 2, marginTop: 1 }} inputProps={{}} label={"Versicherungsgesellschaft (Freitext)"}  onChange={(event) => {this.setState({insuranceagency: event.target.value})}} value={this.state.insuranceagency}   type="text" fullWidth={true}></TextField>
                            </FormControl>
                        </div>
                   {/*  </Paper> */}
                </Grid>

                <Grid item xs={12} md={4}>
                    {/*<Paper className={"minHeight"} style={{padding: "16px"}}>*/}
{/*                         <Typography sx={{ fontSize: 16, marginBottom: 3 }} color="secondary" gutterBottom>
                        Schadenmeldung
                        </Typography> */}
                        {/* <Typography variant='body2' color="text.secondary" sx={{fontSize: "10pt", marginBottom: 1}}>
                            Was ist passiert?
                        </Typography> */}
                        <TextField required={true} disabled={this.state.emailVerified == false} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} onChange={(event) => {this.setState({issueDate: event.target.value})}} value={this.state.issueDate} label={"Vorfallsdatum"} type="date" fullWidth={true}></TextField>
        
                        <TextField disabled={this.state.emailVerified == false} sx={{ fontSize: 14, marginBottom: 2 }} inputProps={{}} label={"Vorfallsort"} placeholder='Wo ist es passiert?' onChange={(event) => {this.setState({issueLocation: event.target.value})}} value={this.state.issueLocation} type="text" fullWidth={true}></TextField>

                        <TextField required={true} disabled={this.state.emailVerified == false} multiline={true} sx={{ fontSize: 14, marginBottom: 2 }} rows={10} inputProps={{}} onChange={(event) => {this.setState({issueText: event.target.value})}} value={this.state.issueText} placeholder='Bitte beschreibe den Schaden so genau wie möglich. Nimm dir ein bisschen Zeit. Je genauer du den Schaden beschreibst, desto schneller bekommst du deinen Schotter ;-)' label={"Beschreibung"} type="text" fullWidth={true}></TextField>
                        <Typography variant='body2' color="text.secondary" sx={{fontSize: "10pt", marginBottom: 1}}>
                        Bitte fotografiere den Schaden so genau wie möglich und lade alle relevanten Dokumente hoch.<br/>
                        z.B.: Ankaufsrechnung, Kostenvoranschläge, Fotos (von allen Seiten)<br/>
                        </Typography>
                        <DropzoneArea  
                            inputProps={{ disabled: this.state.emailVerified == false }}        
                            onChange={this.handleFilesChange.bind(this)}
                            fileObjects={[]}
                            initialFiles={this.state.files}
                            onAlert={this.handleFileUploadError}
                            dropzoneText='Fotos und andere wichtige Dateien hier hochladen'
                            maxFileSize={(30*1024*1024)} 
                        />

                    {/*</Paper>*/}
                </Grid>

                <Grid item xs={12} md={4}>
                    {/*<Paper className={"minHeight"} style={{padding: "16px"}}>*/}

                
                        <FormControlLabel onChange={(event, checked) => {this.setState({dataPrivacyCheckbox: checked})}} disabled={this.state.emailVerified == false} sx={{}} control={<Checkbox />} label={
                            <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                                Hiermit akzeptiere ich die Datenschutzbestimmungen
                            </Typography>
                        } />
                        <Typography variant='body2' color="text.secondary" sx={{fontSize: "10pt", marginBottom: 1}}>
                            Bitte lies dir unsere <a href="#" onClick={(evt) => {this.setState({privacyTermsModalOpen: true})}}>Datenschutzerklärung</a> gründlich durch und bestätige diese mit dem Aktivieren der Checkbox.
                        </Typography>

                        <FormControlLabel onChange={(event, checked) => {this.setState({termsCheckbox: checked})}} disabled={this.state.emailVerified == false} sx={{}} control={<Checkbox />} label={
                            <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                               Hiermit akzeptiere ich die <b>SEPP-AGB</b>
                            </Typography>
                        } />
                        <Typography variant='body2' color="text.secondary" sx={{fontSize: "10pt", marginBottom: 2}}>
                           <a href="../assets/files/Sepp_AGB_A4_2023_V01.pdf" target={"_blank"}>Allgemeine Geschäftsbedingungen (AGB) </a>
                        </Typography>
                        
                        <FormControlLabel onChange={(event, checked) => {this.setState({proxyAuthoritityCheckbox: checked})}} disabled={this.state.emailVerified == false} sx={{marginTop: 2}} control={<Checkbox />} label={
                            <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                                Hiermit erteile ich <b>seppside.com</b> eine Vollmacht zur Durchführung dieser Schadenmeldung
                            </Typography>
                        } />
                        <Typography variant='body2' color="text.secondary" sx={{fontSize: "10pt", marginBottom: 1}}>
                            Bitte lies dir unsere <a href="../assets/files/Sepp_Vollmacht_A4_2023_V01.pdf" target={"_blank"}>Vollmacht</a> gründlich durch und bestätige diese mit dem Aktivieren der Checkbox.
                        </Typography>
                        <Button onClick={(event) => {this.setState({whyProxyAuthModalOpen: true})}} sx={{marginBottom: 2, marginTop: 1}} size="small" variant="outlined" fullWidth={true}>Warum muss ich das tun?</Button>

                        <FormControlLabel onChange={(event, checked) => {this.setState({craftsmanServiceCheckbox: checked})}} disabled={this.state.emailVerified == false} sx={{marginBottom: 2}} control={<Checkbox defaultChecked={true} />} label={
                            <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                               <b>SORGLOS-PAKET:</b> ja, bitte kümmere dich um eine <b>SEPP-Handwerkervermittlung</b>
                            </Typography>
                        } />

                        <Typography variant='body2' color="text.secondary" sx={{fontSize: "10pt", marginBottom: 1, textAlign: 'center', textTransform: 'uppercase'}}>
                            <b>Deine Kosten: 0 €</b>
                        </Typography>

                        <Typography sx={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold' }} color='text.secondary'>
                            Was machen wir alles kostenlos für dich?
                        </Typography>
                        <Typography variant='body2' color="text.secondary" sx={{fontSize: "10pt", marginBottom: 2}}>
                        <li>Organisation und verbindliche Beauftragung aller notwendigen Handwerksarbeiten</li>
                        <li>Terminvorbereitungen innerhalb von 48h</li>
                        <li>Zeitersparnis</li>
                        <li>Organisation eines Gutachters</li>
                        <li>Direktverrechnung über den Versicherer (keine Vorleistung)</li>
                        </Typography>
                        <Typography sx={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold' }} color='text.secondary'>
                            Achtung!
                        </Typography>
                        <Typography variant='body2' color="text.primary" sx={{fontSize: "9pt", marginBottom: 2, marginTop: 0}}>
                        Falls du diesen Service nach Beauftragung stornierst, fällt eine Stornierungspauschale in Höhe von <b>50 €</b> an.
                        </Typography>
                        <Button onClick={(event) => {this.setState({workermanServiceDetailsModalOpen: true})}} sx={{marginBottom: 0, marginTop: 0}} size="small" variant="text" fullWidth={true}>Details</Button>

                        <Button disabled={this.state.emailVerified == false || this.state.showLoading_Login} sx={{marginTop: 4, backgroundColor: "white"}} size="large" variant="outlined" onClick={this.onClick_Create} fullWidth={true}>
                            Meldung erstellen
                            <ArrowRightAltIcon></ArrowRightAltIcon>                            
                        </Button>
                        <div style={{display: (this.state.showLoading_Login == true)?"block":"none"}}>
                            <LinearProgress />
                        </div>


                        <Modal
                            open={this.state.creationCompleteModal}
                            onClose={this.setModalClose} 
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            disableEscapeKeyDown={true}

                            >
                            <Box sx={modalStyle}>   
                    
                                <Grid container>
                                    <Grid item xs={12} md={8}>
                                        <Typography sx={{ fontSize: 16, marginBottom: 3 }} color="primary" gutterBottom>
                                            Meldung erfolgreich erfasst
                                        </Typography>
                                        <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                            Deine Schadenmeldung ist bei uns eingegangen und wird bearbeitet.
                                        </Typography>
                                        <Button sx={{marginTop: 4}} size="large" variant="outlined"  component={Link} to="/issues" fullWidth={true}>Weiter</Button>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img className={"hideSmall"} style={{ maxWidth: "80%" }} src={seppOk} />
                                            <img className={"hideLarge"} style={{ maxWidth: "80%" }} src={seppOkSmall} />
                                        </div>
                                    </Grid>
                                </Grid>

            
                
                            </Box>
                        </Modal>

                        <Modal
                            open={this.state.mandatoryFieldsMissingModalOpen}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"

                            >
                            <Box sx={modalStyleMini}>
                                <Typography sx={{ fontSize: 16, marginBottom: 3 }} color="primary" gutterBottom>
                                    Bitte fülle alle Pflichfelder aus.
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                    {this.state.mandatoryFieldsMissingTxt}
                                </Typography>
                                <Button onClick={(event) => {this.setState({mandatoryFieldsMissingModalOpen: false})}} sx={{marginBottom: 2, marginTop: 1}} size="small" variant="outlined" fullWidth={true}>Zurück zum Formular</Button>
                            </Box>
                        </Modal>

                        <Modal
                            open={this.state.whyProxyAuthModalOpen}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            onClose={(event) => {this.setState({whyProxyAuthModalOpen: false})}}
                            disableEscapeKeyDown={true}
                            >
                            <Box sx={modalStyleMini}>
                                <Typography sx={{ fontSize: 16, marginBottom: 3 }} color="primary" gutterBottom>
                                    Warum benötigt SEPP eine Vollmacht von mir?
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                    Nur mit deiner Einwilligung kann SEPP die Schadensmeldung und –korrespondenz mit der Versicherung übernehmen.
                                </Typography>
                                <Button onClick={(event) => {this.setState({whyProxyAuthModalOpen: false})}} sx={{marginBottom: 2, marginTop: 1}} size="small" variant="outlined" fullWidth={true}>Zurück zum Formular</Button>
                            </Box>
                        </Modal>

                        <Modal
                            open={this.state.workermanServiceDetailsModalOpen}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            onClose={(event) => {this.setState({workermanServiceDetailsModalOpen: false})}}
                            disableEscapeKeyDown={true}
                            >
                            <Box sx={modalStyleMini}>
                                <Typography sx={{ fontSize: 16, marginBottom: 3 }} color="primary" gutterBottom>
                                    SEPP-Hardwerkervermittlung
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Ich kümmere mich darum, dass du schnellst möglich alle Handwerker bekommst, die für deinen Schaden notwendig sind. Mit unserem Partnernetzwerk meldet sich dieser innerhalb von 24 Stunden bei dir.
                                </Typography>

                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                    <b>Beispiel Unfallversicherungsschaden</b>: Du hast dir z.B. einen Bänderriss im Knöchel zugezogen. Nachdem du alle notwendigen Heilungsmaßnahmen durchgeführt hast kannst du innerhalb 15. Monaten ( längere Fristen möglich) einen Dauerinvaliditätsanspruch stellen, wo dann durch ein Gutachten bei einen Arzt, die Feststellung der Bewegungseinschränkung gemacht wird. Versäumst du die Frist hast du keinen Leistungsanspruch mehr. Hier mache ich dich aufmerksam, dass du zu deinem Anspruch kommst.
                                </Typography>
                                <Button onClick={(event) => {this.setState({workermanServiceDetailsModalOpen: false})}} sx={{marginBottom: 2, marginTop: 1}} size="small" variant="outlined" fullWidth={true}>Zurück zum Formular</Button>
                            </Box>
                        </Modal>

                        <Modal
                            open={this.state.privacyTermsModalOpen}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            onClose={(event) => {this.setState({privacyTermsModalOpen: false})}}
                            disableEscapeKeyDown={true}
                            >
                            <Box sx={modalStyleMini}>
                                <Typography sx={{ fontSize: 16, marginBottom: 3 }} color="primary" gutterBottom>
                                    DATENSCHUTZ
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Wir versichern unseren Kunden, persönliche Daten, die uns bei Unterzeichnung des Antrages überlassen
werden, absolut vertraulich zu behandeln. Wenn Sie sich für SPEKTRUM Schadenmanagement entschieden
haben, benötigen wir von Ihnen alle persönlichen Daten, welche ausführlich bei der Dateneingabe abgefragt
werden.<br/><br/>
Wir verkaufen keine persönlichen Daten und überlassen Ihre Daten keinen Dritten. Wir verwenden die angege-
benen Daten im Sinne unserer Kunden lediglich für interne Zwecke. Sämtliche Daten aus Anträgen, Anfragen,
Schadensfällen oder sonstigen tagesgeschäftlichen Abwicklungen werden elektronisch gespeichert und nur
für Zwecke der Firma SPEKTRUM Schadenmanagement und deren Kunden verwendet. Es werden keine Daten
an Dritte, insbesondere Werbefirmen und Adressverlage weitergegeben. Ein ausdrücklicher Hinweis bzw. die
Ausübung des Widerspruchsrechtes des Kunden betreffend der Datenweitergabe an Adressverlage gem.
§ 151 Abs.5 GewO ist daher nicht notwendig.
                                </Typography>

                                <Button onClick={(event) => {this.setState({privacyTermsModalOpen: false})}} sx={{marginBottom: 2, marginTop: 1}} size="small" variant="outlined" fullWidth={true}>Zurück zum Formular</Button>
                            </Box>
                        </Modal>


                        <Modal
                            open={this.state.termsDetailsModalOpen}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            onClose={(event) => {this.setState({termsDetailsModalOpen: false})}}
                            disableEscapeKeyDown={true}
                            >
                            <Box sx={modalStyle}>
                                <Typography sx={{ fontSize: 20, marginBottom: 3 }} color="primary" gutterBottom>
                                Allgemeine Geschäftsbedingungen
                                </Typography>

                                <Typography sx={{ fontSize: 18, marginBottom: 3 }} color="secondary" gutterBottom>
                                Allgemeines
                                </Typography>

                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Nachstehende Bedingungen gelten für alle Verträge zwischen SEPP GmbH und dem Antragsteller sowie dem/der Vertragspartner/in.<br/><br/>
SEPP GmbH vermittelt Dienstleistungen von ihren Partnerunternehmen. Für die Leistungen dieser Vertragspartner übernimmt SEPP GmbH keine Haftung. Ein Gewährleistungsanspruch des Kunden gegen SEPP GmbH besteht nicht. Die AGBs und die darin festgelegten Preise können einseitig von SEPP GmbH durch schriftlichen Hinweis an die Vertragspartner geändert werden. Die Zustimmung des Vertragspartners zur Vertragsänderung wird angenommen, wenn dieser nicht ausdrücklich innerhalb von zwei Monaten widerspricht.
<br/><br/>Alle angeführten Leistungen und Konditionen gelten ausschließlich an die von SEPP GmbH vermittelten Betriebe und Partner.
                                </Typography>


                                <Typography sx={{ fontSize: 18, marginBottom: 3 }} color="secondary" gutterBottom>
                                Wo können die Leistungen in Anspruch genommen werden?
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Die Serviceleistungen – Selbstbehaltsreduktion und Leihauto, können nur bei den SEPP GmbH genannten Partnern und vermittelten Werkstätten in Anspruch genommen werden. Wählt der Kunde eine andere Werkstätte oder einen anderen Partner, verzichtet er damit auf die genannten Leistungen. Wenn kein Reparaturauftrag erteilt wird bzw. erteilt werden kann (z.B.: Totalschaden des KFZ), besteht kein Anspruch auf die genannte Leistung. 
                                </Typography>


                                <Typography sx={{ fontSize: 18, marginBottom: 3 }} color="secondary" gutterBottom>
                                Ausschlüsse
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Die vermittelten Leistungen von SEPP GmbH gelten nicht bei: Totalschaden und jeglicher Art von Verschleißteilreparaturen. Alle Pakete beziehen sich ausschließlich auf Kfz bis 3,5 t höchst zulässigem Gesamtgewicht, in weiterer Folge auf PKW und Kombis. Für andere Fahrzeuge (LKW, Kastenwägen, Anhänger etc.) bedarf es einer gesonderten Vereinbarung.
                                </Typography>

                                <Typography sx={{ fontSize: 18, marginBottom: 3 }} color="secondary" gutterBottom>
                                Auftragsstornierung
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Wird ein Auftrag der Partnerhandwerker oder Partnerwerkstätten erteilt und dieser storniert, werden 50,00 (fünfzig) Euro in Rechnung gestellt.
                                </Typography>

                                <Typography sx={{ fontSize: 18, marginBottom: 3 }} color="secondary" gutterBottom>
                                Kosten
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Wird der Erinnerungsservice in der Unfallversicherung nach einem Personenunfall mit Verletzung genutzt, damit der Versicherer ein Gutachten zur Feststellung der Dauerinvalidität, unter Einhaltung der vorgegebenen Fristen, beauftragt werden 10% der Entschädigungsleistung als Bearbeitungshonorar verrechnet.
<br/>Wird ein Auftrag der Partnerhandwerker oder Partnerwerkstätten erteilt und dieser storniert, werden 50,00 (fünfzig) Euro in Rechnung gestellt.

                                </Typography>

                                <Typography sx={{ fontSize: 18, marginBottom: 3 }} color="secondary" gutterBottom>
                                Vertragspartner
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Unser Ziel ist es, jedem Kunden in jedem Schadenfall eine optimale Schadenabwicklung anbieten zu können. Für diesen Fall haben wir speziell ausgewählten Partnerbetriebe, bei denen wir auf hohe Qualität achten und diese auch laufend überprüfen. Wir vermitteln ausschließlich ausgewählte österreichische KFZ- Fachwerkstätten, Partnerunternehmen und Partner. Einen Überblick über unsere Vertragspartner erhalten Sie unter 0800 400 440. Dadurch finden Sie den optimalen Partner in Ihrer Nähe und stellen einen reibungslosen Ablauf im Schadensfall sicher.
                                </Typography>

                                <Typography sx={{ fontSize: 18, marginBottom: 3 }} color="secondary" gutterBottom>
                                Datenschutz
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Wir versichern unseren Kunden, persönliche Daten, die uns bei Unterzeichnung des Antrages überlassen werden, absolut vertraulich zu behandeln. Wenn Sie sich für SEPP GmbH entschieden haben, benötigen wir von Ihnen alle persönlichen Daten, welche ausführlich bei der Dateneingabe abgefragt werden.
Wir verkaufen keine persönlichen Daten und überlassen Ihre Daten keinen Dritten. Wir verwenden die angegebenen Daten im Sinne unserer Kunden lediglich für interne Zwecke. Sämtliche Daten aus Anträgen, Anfragen, Schadensfällen oder sonstigen tagesgeschäftlichen Abwicklungen werden elektronisch gespeichert und nur für Zwecke der Firma SEPP GmbH und deren Kunden verwendet. Es werden keine Daten an Dritte, insbesondere Werbefirmen und Adressverlage weitergegeben. Ein ausdrücklicher Hinweis bzw. die Ausübung des Widerspruchsrechtes des Kunden betreffend der Datenweitergabe an Adressverlage gem. § 151 Abs.5 GewO ist daher nicht notwendig.

                                </Typography>

                                <Typography sx={{ fontSize: 18, marginBottom: 3 }} color="secondary" gutterBottom>
                                Schadensablehnungen, Deckungszusagen
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Wir übermitteln für unsere Kunden die beigebrachten Schadenunterlagen wie z.B. Fotos, Kostenvoranschläge, Rechnungen, usw. Schadensablehnungen oder Deckungszusagen werden nur nach Information der Versicherung weitergegeben. Vorzeitige Schadensablehnungen oder Deckungszusagen werden von uns nicht getätigt.
Für den Streitfall einer Schadensablehnung stellen wir unsere Versicherungsexperten und Rechtsanwälte für die Prüfung zur Verfügung. Diese sind für das kostenlose Erstgespräch unter 0800 400 440 erreichbar.

                                </Typography>

                                <Typography sx={{ fontSize: 18, marginBottom: 3 }} color="secondary" gutterBottom>
                                Erfüllungsort/Gerichtsort
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Erfüllungsort und Gerichtsort ist Tirol. Wenn der Kunde Konsument im Sinne des KSchG ist, gilt die Zuständigkeit jenes Gerichtes als begründet, in dessen Sprengel der Wohnsitz, der gewöhnliche Aufenthalt oder der Ort der Beschäftigung des Kunden liegt.
                                </Typography>

                                <Typography sx={{ fontSize: 18, marginBottom: 3 }} color="secondary" gutterBottom>
                                Sprache
                                </Typography>
                                <Typography sx={{ fontSize: 12, marginBottom: 3 }} color="secondary" gutterBottom>
                                Die Vertrags- und Geschäftssprache ist Deutsch. Diese AGBs sind gültig ab 01.08.2022
                                </Typography>


                                <Button onClick={(event) => {this.setState({termsDetailsModalOpen: false})}} sx={{marginBottom: 2, marginTop: 1}} size="small" variant="outlined" fullWidth={true}>Zurück zum Formular</Button>
                            </Box>
                        </Modal>
                    {/*</Paper>*/}
                </Grid>
            </Grid>
            {/* <Guide></Guide> */}
            {/*</Paper>*/}

            
            </div>
        );
    }
}

export default CreateIssuePanel;